import React, { useState } from "react";
import MediaQuery from "react-responsive";
import GoogleAppleConnect from "./components/GoogleAppleConntect/GoogleAppleConnect";
import Switcher from "./components/Switcher";
import {
  container,
  hide,
  tabInfo,
  flex_container,
  img,
  arrow_left,
  arrow_right,
} from "./swapper.module.scss";
import MockupActivity from "./components/img/MockupActivity";
import MockupHealth from "./components/img/MockupHealth";
import MockupLocation from "./components/img/MockupLocation";
import Autonomy from "./components/Autonomy/Autonomy";
import NewArnes from "./components/Arnes/NewArnes";

const FeatureSwapper = () => {
  const [selected, setSelectedTab] = useState(1);

  const selectedTab = [
    {
      text: (
        <p>
          Tracking de <b>pasos</b> y <b>tiempo de actividad.</b> de tu perro.
          Reportes <b>diarios, semanales y mensuales. </b>
          <b>Objetivos personalizables</b> para tu perro.
        </p>
      ),
      img: <MockupActivity />,
      index: 1,
      prev: 3,
      next: 2,
    },
    {
      text: (
        <p>
          <b>Seguimiento y sugerencias de</b> un equipo profesional.
          <b>Informes periódicos</b> con el estado y la actividad de tu perro.
        </p>
      ),
      img: <MockupHealth />,
      index: 2,
      prev: 1,
      next: 3,
    },
    {
      text: (
        <p>
          Localización <b>en tiempo real</b> por GPS. Función{" "}
          <b>“perro perdido”.</b>
          Cobertura por todo el territorio <b>nacional.</b>
        </p>
      ),
      img: <MockupLocation />,
      index: 3,
      prev: 2,
      next: 1,
    },
  ];

  return (
    <div className={container}>
      <GoogleAppleConnect />
      <section
        style={{ marginBottom: "5rem" }}
        data-sal="fade"
        data-sal-delay="100"
        data-sal-duration="1000"
      >
        <Switcher selected={selected} setSelectedTab={setSelectedTab} />
        <MediaQuery minWidth={800}>
          <div className={flex_container}>
            {selectedTab.map((item) => {
              return (
                <div className={tabInfo}>
                  {item.text}
                  <div className={img}>{item.img}</div>
                </div>
              );
            })}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={799}>
          {selectedTab.map((item) => (
            <div className={selected === item.index ? tabInfo : hide}>
              {item.text}
              <div
                className={arrow_left}
                onClick={() => setSelectedTab(item.prev)}
                style={{
                  height: "18px",
                }}
              ></div>
              <div className={img}>{item.img}</div>
              <div
                className={arrow_right}
                onClick={() => setSelectedTab(item.next)}
                style={{
                  height: "18px",
                }}
              ></div>
            </div>
          ))}
        </MediaQuery>
      </section>
      <Autonomy />
      <NewArnes/>
    </div>
  );
};

export default FeatureSwapper;
