import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Img = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../images/health_img.png"
      quality={100}
      alt="Salud de tu perro, collar inteligente Kolyy"
    />
  );
};

export default Img;
