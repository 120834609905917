import React from "react";
import Button from "../../Buttons/Button";
import { container, step_container, item, img } from "./steps.module.scss";
import step_img_1 from "../../../images/icons/step_img_1.png";
import step_img_2 from "../../../images/icons/step_img_2.png";
import step_img_3 from "../../../images/icons/step_img_3.png";

const steps = [
  {
    title: "1. Personaliza tu collar",
    text:
      "Escoge el color y la talla que más se adapte a tu perro.",
    img: step_img_1,
  },
  {
    title: "2. Resérvalo",
    text:
      "Rellena el formulario para reservar tu collar Kolyy.",
    img: step_img_2,
  },
  {
    title: "3. Cómpralo",
    text:
      "Cuando el collar esté disponible te enviaremos un mail para que puedas comprarlo antes que nadie.",
    img: step_img_3,
  },
];

const Steps = () => {
  return (
    <section className={container}>
      <h2 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
        Cómo conseguir tu collar Kolyy
      </h2>
      <div className={step_container}>
        {steps.map((step) => (
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={item}
          >
            <div className={img}>
              <img src={step.img} alt={step.title} />
            </div>
            <h3>{step.title}</h3>
            <p>{step.text}</p>
          </div>
        ))}
      </div>
      <br />
      <Button to={"/reserva-collar-kolyy"}>Reserva tu collar</Button>
    </section>
  );
};

export default Steps;
