import React from "react";
import { container, item } from "./banner.module.scss";
import banner_feature_1 from "../../../../images/icons/banner_feature_1.png";
import banner_feature_2 from "../../../../images/icons/banner_feature_2.png";
import banner_feature_3 from "../../../../images/icons/banner_feature_3.png";
import banner_feature_4 from "../../../../images/icons/banner_feature_4.png";
import banner_feature_5 from "../../../../images/icons/banner_feature_5.png";
import banner_feature_6 from "../../../../images/icons/banner_feature_6.png";
import MediaQuery from "react-responsive";

const BannerFeatures = () => {
  return (
    <>
      <MediaQuery maxWidth={870}>
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={container}
        >
          <div className={item}>
            <img src={banner_feature_1} alt="Localizador GPS" />
            <p>Localizador GPS</p>
          </div>
          <div className={item}>
            <img src={banner_feature_2} alt="Monitor de pasos" />
            <p>Monitor de pasos</p>
          </div>
          <div className={item}>
            <img src={banner_feature_3} alt="Historial de actividad" />
            <p>Historial de actividad</p>
          </div>
        </div>
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={container}
        >
          <div className={item}>
            <img src={banner_feature_4} alt="15 días" />
            <p>15 días</p>
          </div>
          <div className={item}>
            <img src={banner_feature_5} alt="Luz nocturna" />
            <p>Luz nocturna</p>
          </div>
          <div className={item}>
            <img src={banner_feature_6} alt="Sumergible" />
            <p>Sumergible</p>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={870}>
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={container}
        >
          <div className={item}>
            <img src={banner_feature_1} alt="Localizador GPS" />
            <p>Localizador GPS</p>
          </div>
          <div className={item}>
            <img src={banner_feature_2} alt="Monitor de pasos" />
            <p>Monitor de pasos</p>
          </div>
          <div className={item}>
            <img src={banner_feature_3} alt="Historial de actividad" />
            <p>Historial de actividad</p>
          </div>
          <div className={item}>
            <img src={banner_feature_4} alt="15 días" />
            <p>15 días</p>
          </div>
          <div className={item}>
            <img src={banner_feature_5} alt="Luz nocturna" />
            <p>Luz nocturna</p>
          </div>
          <div className={item}>
            <img src={banner_feature_6} alt="Sumergible" />
            <p>Sumergible</p>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default BannerFeatures;
