import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const TabHealth = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../../images/tab_health.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default TabHealth;
