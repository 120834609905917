import React from "react";
import { subscribe_container, bg_img } from "../about.module.scss";
import SubscribeComponent from "../../../blogComponents/subscribeComponent";
import bgImg from "../../../../images/bg_subscription.png";

const Subscribe = () => {
  return (
    <div style={{position:'relative'}}>
      <div className={subscribe_container}>
        <h3 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
          ¿Quieres estar al corriente de todo?
        </h3>
        <p data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
          Suscríbete y entérate de todas las noticias y actualizaciones del
          collar.
        </p>
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          style={{ margin: "0 auto", zIndex:99 }}
        >
          <SubscribeComponent />
        </div>
      </div>
      <div className={bg_img}>
        <img src={bgImg} alt="" />
      </div>
    </div>
  );
};

export default Subscribe;
