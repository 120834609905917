import React from "react";
import { container, item, info, img_container } from "./features.module.scss";
import feature_1 from "../../../images/icons/feature_1.png"
import feature_2 from "../../../images/icons/feature_2.png"
import feature_3 from "../../../images/icons/feature_3.png"
import feature_4 from "../../../images/icons/feature_4.png"

const features = [
  {
    title: "Localizador GPS",
    text: "Ten siempre controlada la ubicación de tu amigo peludo.",
    img: feature_1,
  },
  {
    title: "Monitor de Actividad ",
    text:
      "Registra los pasos y la actividad diaria, semanal y mensual de tu perro.",
    img: feature_2,
  },
  {
    title: "Diagnóstico",
    text:
      "Consigue informes mensuales de la actividad y bienestar de tu perro.",
    img: feature_3,
  },
  {
    title: "Registro de paseos",
    text:
      "Registra los paseos y guarda rutas para compartir con quién quieras.",
    img: feature_4,
  },
];

const Features = () => {
  return (
    <section className={container}>
      {features.map((feature) => (
        <div
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={item}
        >
          <div className={img_container}>
          <img src={feature.img} alt={feature.title} />
          </div>
          <div className={info}>
            <h3>{feature.title}</h3>
            <p> {feature.text}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Features;
