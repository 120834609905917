// extracted by mini-css-extract-plugin
export var abs = "introCard-module--abs--J2G5s";
export var btn_container = "introCard-module--btn_container--MXXuT";
export var container = "introCard-module--container--OjiaI";
export var flex = "introCard-module--flex--M+n7u";
export var img = "introCard-module--img--YGA3g";
export var info_container = "introCard-module--info_container--OI68u";
export var interrogant = "introCard-module--interrogant--rh2el";
export var only_kolyer_btn = "introCard-module--only_kolyer_btn--1Vc7K";
export var subscription_container = "introCard-module--subscription_container--7BTCT";
export var text = "introCard-module--text--aUP2T";