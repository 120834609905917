import React from "react";
import { health_features_container, item } from "../health.module.scss";
import health_feature_1 from "../../../../images/icons/health_feature_1.png";
import health_feature_2 from "../../../../images/icons/health_feature_2.png";
import MediaQuery from "react-responsive";

const features = [
  {
    title: "El sobrepeso, un gran problema:",
    text:
      "El 60% de los perros sufre de sobrepeso y esto conlleva multitud de riesgos como enfermedades cardiovasculares, respiratorias o articulatorias.",
    img: health_feature_1,
  },
  {
    title: "Esconden el dolor:",
    text:
      "Por su naturaleza animal los perros no muestran síntomas de enfermedades o dolencias hasta que el problema es severo.",
    img: health_feature_2,
  },
];
const HealthFeatures = () => {
  return (
    <section className={health_features_container}>
      {features.map((feature) => (
        <div
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={item}
        >
          <div>
            <MediaQuery maxWidth={870}>
              <img src={feature.img} alt={feature.title} />
            </MediaQuery>
            <h6>{feature.title}</h6>
            <p>{feature.text}</p>
          </div>
          <MediaQuery minWidth={870}>
            <img src={feature.img} alt={feature.title} />
          </MediaQuery>
        </div>
      ))}
    </section>
  );
};

export default HealthFeatures;
