import React from "react";
import { location_features_container, item } from "../location.module.scss";
import location_feature_1 from "../../../../images/icons/location_feature_1.png";
import location_feature_2 from "../../../../images/icons/location_feature_2.png";
import location_feature_3 from "../../../../images/icons/location_feature_3.png";
import location_feature_4 from "../../../../images/icons/location_feature_4.png";
import MediaQuery from "react-responsive";

const features = [
  {
    title: "Localización WIFI:",
    text:
      "Kolyy permite almacenar y reconocer hasta 5 direcciones de hogar conocidas. Si el collar sale de la zona segura, empezará a registrar su posición al instante.",
    img: location_feature_1,
  },
  {
    title: "Posicionamiento 2G:",
    text:
      "Kolyy registra la localización de las antenas a las que se conecta para devolver su posición en cuestión de segundos.",
    img: location_feature_2,
  },
  {
    title: "Localización GPS:",
    text:
      "Una vez activado el modo perro perdido, podrás localizar a tiempo real la posición de tu perro de manera muy fácil en tu aplicación.",
    img: location_feature_3,
  },
  {
    title: "Comunidad #0perrosperdidos:",
    text:
      "Con su foto y sus datos de contacto para que, en caso de que no estes cerca, puedas recuperarlo lo más rápido posible.",
    img: location_feature_4,
  },
];
const LocationFeatures = () => {
  return (
    <section className={location_features_container}>
      {features.map((feature) => (
        <div
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={item}
        >
          <div>
            <MediaQuery maxWidth={870}>
              <img src={feature.img} alt={feature.title} />
            </MediaQuery>
            <h6>{feature.title}</h6>
            <p>{feature.text}</p>
          </div>
            <MediaQuery minWidth={870}>
              <img src={feature.img} alt={feature.title} />
            </MediaQuery>
        </div>
      ))}
    </section>
  );
};

export default LocationFeatures;
