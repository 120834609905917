import React from "react";
import { container, img, flex } from "./health.module.scss";
import Img from "./Img";
import HealthFeatures from "./components/HealthFeatures";
import MediaQuery from "react-responsive";

const Health = () => {
  return (
    <div className={container}>
      <div className={flex}>
        <div>
          <MediaQuery minWidth={870}>
            <div
              data-sal="fade"
              data-sal-delay="100"
              data-sal-duration="1000"
              className={img}
            >
              <Img />
            </div>
          </MediaQuery>
        </div>
        <div>
          <h2 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
            Anticípate a los problemas
          </h2>
          <HealthFeatures />
          <MediaQuery maxWidth={870}>
            <div
              data-sal="fade"
              data-sal-delay="100"
              data-sal-duration="1000"
              className={img}
            >
              <Img />
            </div>
          </MediaQuery>
          <br />
          <p data-sal="fade" data-sal-delay="100" data-sal-duration="1000">
            Llevar un seguimiento y un control diario de su estado físico es la
            forma más fácil y eficaz de anticiparte y evitar riesgos.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Health;
