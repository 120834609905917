import React, { useEffect } from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import Intro from "../components/_Landing/Intro/Intro";
import Features from "../components/_Landing/Features/Features";
import Feedbacks from "../components/_Landing/Feedback/Feedbacks";
import Steps from "../components/_Landing/Steps/Steps";
import Location from "../components/_Landing/Location/Location";
import Health from "../components/_Landing/Health/Health";
import FeatureSwapper from "../components/_Landing/FeatureSwapper/FeatureSwapper";
import Contact from "../components/_Landing/Contact/Contact";
import About from "../components/_Landing/About/About";
import CollarInfo from "../components/_Landing/CollarInfo/CollarInfo";
import ImgCollection from "../components/_Landing/ImgCollection/ImgCollection";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Seo title="Collar para cuidar mi perro" />
      <Intro />
      <Features />
      <Feedbacks />
      <Steps />
      <Location />
      <Health />
      <FeatureSwapper />
      <CollarInfo/>
      <ImgCollection/>
      <About />
      <Contact />
    </Layout>
  );
};
export default HomePage;
