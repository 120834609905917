import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const TabActivity = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../../images/tab_activity.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default TabActivity;
