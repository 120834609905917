import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ArnesImg = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../../images/arnes-inteligente-para-perros.png"
      alt="Kolyy, Arnés inteligente para perros"
    />
  );
};

export default ArnesImg;
