import React from "react";
import GreenCounterContainer from "./components/GreenCounterContainer";
import FaQs from "./components/FaQs";
import Subscribe from "./components/Subscribe";

const About = () => {
  return (
    <>
      <GreenCounterContainer />
      <FaQs />
      <Subscribe/>
    </>
  );
};

export default About;
