import React from "react";
import { container, img, flex } from "./location.module.scss";
import Img from "./Img";
import LocationFeatures from "./components/LocationFeatures";
import MediaQuery from "react-responsive";

const Location = () => {
  return (
    <div className={container}>
      <div className={flex}>
        <div>
          <MediaQuery minWidth={870}>
            <div
              data-sal="fade"
              data-sal-delay="100"
              data-sal-duration="1000"
              className={img}
            >
              <Img />
            </div>
          </MediaQuery>
        </div>
        <div>
          <h2 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
            Localiza a tu perro rápido
          </h2>
          <p data-sal="fade" data-sal-delay="100" data-sal-duration="1000">
            <b>
              1 de cada 3 perros se pierde alguna vez en su vida y solo la mitad
              vuelve con sus familias. Las primeras horas de una desaparición
              son vitales.
            </b>
          </p>
          <br />
          <p data-sal="fade" data-sal-delay="100" data-sal-duration="1000">
            Con el collar Kolyy puedes ver en el mapa de tu teléfono móvil, la
            posición exacta de tu perro.
          </p>

          <MediaQuery maxWidth={870}>
            <div
              data-sal="fade"
              data-sal-delay="100"
              data-sal-duration="1000"
              className={img}
            >
              <Img />
            </div>
          </MediaQuery>
          <br />
          <p data-sal="fade" data-sal-delay="100" data-sal-duration="1000">
            El collar kolyy utiliza diferentes métodos de localización:
          </p>
          <LocationFeatures />
        </div>
      </div>
    </div>
  );
};

export default Location;
