import { Link } from "gatsby";
import React from "react";
import { button } from "./button.module.scss";

const Button = ({ children, to, style }) => {
  return (
    <div data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
      <Link to={to}>
        <button style={style} className={button}>
          {children}
        </button>
      </Link>
    </div>
  );
};

export default Button;
