import React from "react";
import { container, item_container, flex, number } from "./autonomy.module.scss";

const autonomy = [
  {
    color: "#D1F9FB",
    text: "Solo paseos:",
    days: "15 días",
  },
  {
    color: "#95EED3",
    text: "Durante el día:",
    days: "10 días",
  },
  {
    color: "#BCEE95",
    text: "Día y noche:",
    days: "8 días",
  },
];
const Autonomy = () => {
  return (
    <div className={container}>
      <h2 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
        Más autonomía que cualquier otro GPS
      </h2>
      <p data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
        Puedes elegir entre tres modos de uso para ahorrar batería.
      </p>
      <div className={flex}>
        {autonomy.map((item) => (
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={item_container}
            style={{ backgroundColor: item.color }}
          >
            <p>{item.text}</p>
            <p className={number}>{item.days}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Autonomy;
