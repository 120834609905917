import React, { useState, useEffect } from "react";
import { container, img, text } from "./color_change.module.scss";
import MediaQuery from "react-responsive";
import BlackCollar from "./components/img/BlackCollar";
import BlueCollar from "./components/img/BlueCollar";
import RedCollar from "./components/img/RedCollar";
import ColorSwapper from "./components/ColorSwapper";

const ColorChange = () => {
  const [color, setColor] = useState("#1F1F1F");
  const [collar, setCollar] = useState(<BlackCollar />);

  const setBlack = () => {
    setCollar(<BlackCollar />);
  };
  const setBlue = () => {
    setCollar(<BlueCollar />);
  };
  const setRed = () => {
    setCollar(<RedCollar />);
  };

  useEffect(() => {
    switch (color) {
      case "#1F1F1F":
        return setBlack();
      case "#30AAAA":
        return setBlue();
      case "#812439":
        return setRed();
      default:
        break;
    }
  }, [color]);
  return (
    <div className={container}>
        <section
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="1000"
          className={text}
        >
          <section
            data-sal="fade"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={img}
          >
            {collar}
          </section>
          <ColorSwapper color={color} setColor={setColor} />
        </section>
    </div>
  );
};

export default ColorChange;
