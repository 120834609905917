// extracted by mini-css-extract-plugin
export var arrow_left = "swapper-module--arrow_left--0rFFV";
export var arrow_right = "swapper-module--arrow_right--ugccl";
export var container = "swapper-module--container--Q0Qva";
export var flex_container = "swapper-module--flex_container--r4wNk";
export var grayIcon = "swapper-module--grayIcon--sA9IT";
export var hide = "swapper-module--hide--MbqD+";
export var icon = "swapper-module--icon--rJOb+";
export var icon_desk = "swapper-module--icon_desk--CRvBl";
export var img = "swapper-module--img--9RpnJ";
export var move_left = "swapper-module--move_left--TohYz";
export var move_right = "swapper-module--move_right--+OTnw";
export var tab = "swapper-module--tab--NBCpP";
export var tabInfo = "swapper-module--tabInfo--Eyi1O";
export var tabs = "swapper-module--tabs--dsqfu";