import React from "react";
import MediaQuery from "react-responsive";
import Button from "../../../../Buttons/Button";
import { container, img, nuevo } from "./arnes.module.scss";
import ArnesImg from "./ArnesImg";

const NewArnes = () => {
  return (
    <>
      <MediaQuery maxWidth={870}>
        <section className={container}>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={nuevo}
          >
            New
          </div>
          <div>
            <h2
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
            >
              Ya está disponible el arnés Kolyy
            </h2>
            <p
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
            >
              Los más pequeños también merecen estar protegidos y en plena
              forma. Disponible en 3 colores y con todas las prestaciones del
              collar.
            </p>
          </div>
          <div
            data-sal="fade"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={img}
          >
            <ArnesImg />
            <Button
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
              to="/reserva-collar-kolyy"
            >
              Reserva tu arnés
            </Button>
          </div>
        </section>
      </MediaQuery>

      <MediaQuery minWidth={870}>
        <section className={container}>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={nuevo}
          >
            New
          </div>
          <div>
            <h2
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
            >
              Ya está disponible el arnés Kolyy
            </h2>
            <p
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
            >
              Los más pequeños también merecen estar protegidos y en plena
              forma. Disponible en 3 colores y con todas las prestaciones del
              collar.
            </p>
            <Button
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
              to="/reserva-collar-kolyy"
            >
              Reserva tu arnés
            </Button>
          </div>
          <div
            data-sal="fade"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={img}
          >
            <ArnesImg />
          </div>
        </section>
      </MediaQuery>
    </>
  );
};

export default NewArnes;
