import React from "react";
import { tabs, tab, icon, desk_icons, move_left, move_right, icon_desk } from "../swapper.module.scss";
import locationIcon from "../../../../images/icons/location_icon.png";
import healthIcon from "../../../../images/icons/health_icon.png";
import activityIcon from "../../../../images/icons/activity_icon.png";
import MediaQuery from "react-responsive";
import TabActivity from "./img/TabActivity";
import TabHealth from "./img/TabHealth";
import TabLocation from "./img/TabLocation";

const Switcher = ({ setSelectedTab, selected }) => {
  const tabCollection = [
    {
      name: "Actividad",
      number: 1,
      url: <TabActivity />,
      img: activityIcon,
      prev: 3,
      next: 2,
    },
    {
      name: "Salud",
      number: 2,
      url: <TabHealth />,
      img: healthIcon,
      prev: 1,
      next: 3,
    },
    {
      name: "Localización",
      number: 3,
      url: <TabLocation />,
      img: locationIcon,
      prev: 2,
      next: 1,
    },
  ];
  return (
    <nav className={tabs}>
      {tabCollection.map((item) => {
        return (
          <div className={tab}>
            <MediaQuery maxWidth={800}>
              <div
                className={move_left}
                onClick={() => setSelectedTab(item.prev)}
                style={{
                  display: selected !== item.number && "none",
                }}
              ></div>

              <div
                className={icon}
                style={{ display: selected !== item.number && "none" }}
              >
                {item.url}
              </div>

              <div
                className={move_right}
                onClick={() => setSelectedTab(item.next)}
                style={{
                  display: selected !== item.number && "none",
                }}
              ></div>
            </MediaQuery>
            <MediaQuery minWidth={800}>
              <div className={desk_icons}>
                <img className={icon_desk} src={item.img} alt={item.name} />
              </div>
            </MediaQuery>
          </div>
        );
      })}
    </nav>
  );
};

export default Switcher;
