import React from "react";
import BannerFeatures from "./BannerFeatures/BannerFeatures";
import { container, img } from "./intro.module.scss";
import MediaQuery from "react-responsive";
import Button from "../../Buttons/Button";
import Img from "./IntroImg/Img";

const Intro = () => {
  return (
    <>
      <MediaQuery maxWidth={870}>
        <div className={container}>
          <h1 data-sal="slide-up" data-sal-delay="100" data-sal-duration="1000">
            El collar inteligente para perros
          </h1>
          <div className={img} data-sal="fade" data-sal-delay="100" data-sal-duration="1000">
            <Img />
          </div>
          <BannerFeatures />
          <Button to="/reserva-collar-kolyy">Reserva tu collar</Button>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={870}>
        <div className={container}>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
          >
            <h1>El collar inteligente para perros</h1>
            <br />
            <Button to="/reserva-collar-kolyy">Reserva tu collar</Button>
          </div>

          <div className={img} data-sal="fade" data-sal-delay="100" data-sal-duration="1000">
            <Img />
          </div>
        </div>
        <BannerFeatures />
      </MediaQuery>
    </>
  );
};

export default Intro;
