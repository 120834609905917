import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const TabLocation = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../../../images/tab_location.png"
      alt="collar inteligente Kolyy"
    />
  );
};

export default TabLocation;
